@use "src/variable" as base;

.companyDashboard {


  .maxWidth {
    max-width: 1200px;
    margin: 0 auto;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }

  .margin-bottom-42 {
    margin-bottom: 42px;
  }

  .margin-bottom-48 {
    margin-bottom: 48px;
  }

  .margin-left-10 {
    margin-left: 10px;
  }


  .side-margin-16 {
    margin: 0 16px;
  }

  .text-center {
    text-align: center;
  }

  .ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .grey-text {
    color: base.$dark-100;
    opacity: 50%;
  }

  .blue-text {
    color: base.$corp-100;
  }

  .red-text {
    color: base.$corp2-100;
  }

  .green-text {
    color: base.$green2-100;
  }

  .dark-grey-text {
    color: base.$dark-100;
  }

  .white-text {
    color: #FFFFFF;
  }

  .bgImage {
    height: 610px;
    background: url("../../assets/image/bg/company_dashboard_background.png") no-repeat center top;
    background-size: cover;
  }

  .bgImageWithBox {
    height: 466px;
    background: url("../../assets/image/bg/company_dashboard_background.png") no-repeat center top;
    background-size: cover;
    transition: height 0.5s;
  }

  .side-padding-50 {
    padding: 0 50px
  }

  .topPadding {
    padding-top: 48px;
  }

  .sidePadding {
    padding-right: 50px;
    padding-left: 50px;
  }

  .title {
    margin-bottom: 16px;
  }

  .accountInfo {
    flex-direction: column;
    background: #FFFFFF;
    padding: 24px;
    position: relative;

    &.orange {
      border: solid 2px base.$orange-100;
    }

    &.green {
      border: solid 2px base.$green2-100;
    }
  }

  .closeIcon{
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .cardContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 15px 0 0 0;
    width: 100%;
    gap: 40px;
    transition: 0.5s;
  }


  .negativeMargin {
    margin: -70px 0 40px 0;
  }


  .ant-list {
    background-color: #FFFFFF;
  }

  .ant-list-item {
    padding-inline: 16px;
  }

  .ant-list-bordered {
    border-radius: 0;
  }
}
