.myPreference {
  padding: 0 50px;

  .dividerDot > :not(:first-child)::before{
    content: "\00a0  ·  \00a0";
  }

  .user-circle {
    background-color: #e9f4f9;
    border-radius: 50%;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }

  .ease-list-in {
    animation: moveIn 1s;
  }

  .ease-list-out {
    animation: moveOut 1s;
  }

  .animation-none {
    width: 100%;
    animation: none;
  }

  .ant-list-bordered {
    border-radius: 0;
  }

}

@keyframes moveIn {
  from {
    width: 100%
  }
  to {
    width: 60%
  }
}

@keyframes moveOut {
  from {
    width: 60%
  }
  to {
    width: 100%
  }
}