@use "src/variable" as base;

.landingOffer {
  margin-top: 36px;

  .title {
    margin-bottom: 16px;
  }

  .offers {
    .offerCard {
      padding: 24px 20px;

      .dividerDot > :not(:first-child)::before {
        content: "\00a0  ·  \00a0";
      }
    }

    .ant-divider {
      margin: 0;
    }
  }
}