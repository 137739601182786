@use "src/variable" as base;


.admin {

  .margin-right-32 {
    margin-right: 32px;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }

  .margin-left-8 {
    margin-left: 8px;
  }

  .padding-50 {
    padding: 0 50px;
  }

  .companyName {
    color: #208AC6;
  }

  .dateCreated {
    color: #868696;
  }

  .ant-table-wrapper .ant-table {
    border-radius: 0;
    border: 1px solid #E7E7EA;
  }

  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row:hover > td:first-child,
  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td.ant-table-cell-row-hover:first-child,
  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row.ant-table-row-selected > td:first-child {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row:hover > td:last-child,
  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td.ant-table-cell-row-hover:last-child,
  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row.ant-table-row-selected > td:last-child {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td{
    background-color: transparent;
  }

  .ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: unset;
  }

  .admin-circle {
    background-color: rgba(base.$orange-100, 0.1);
    width: 50px;
    height: 50px;
    font-weight: 700;
    border-radius: 50%;
    line-height: 50px;
  }

  .tableWrapper {
    position: relative;
  }

  .page-size-changer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;

    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: base.$dark-100;
      margin-right: 16px;
    }

    .ant-select > .ant-select-selector {
      height: 32px;
    }


  }

  .ant-select-selection-item{
    font-weight: 700;
    font-size: 14px;
  }

  .ant-pagination {
    display: flex;

    li {
      margin: 0 0 0 -1px;
      border: 1px solid base.$dark-light-80;
    }

    li:first-child button,
    li:last-child button {
      border-radius: 0;
    }

    .ant-pagination-prev {
      border-radius: 3px 0 0 3px;
    }

    .ant-pagination-next {
      border-radius: 0 3px 3px 0;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border-radius: 0;
    }

    .ant-pagination-item {
      border-radius: 0;

      a {
        color: base.$corp-100;
      }
    }

    .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
    .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
      color: base.$corp-100;
    }

    .ant-pagination-item-active {
      border-color: base.$dark-light-80;

      a {
        color: base.$dark-100;
      }
    }
  }

  .noCompanies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 44px 0;
    border: 1px solid #E7E7EA;
  }

  .noCompaniesIcon {
    width: 100px;
    height: 100px;
    background-color: rgba(base.$orange-100, 0.1);
    border-radius: 50%;
    margin: 0 auto 24px;
  }

}