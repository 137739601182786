@use "src/variable" as base;

.flex-start {
  display: flex;
  justify-content: flex-start;
}


.noCandidatesIcon {
  width: 100px;
  height: 100px;
  background-color: rgba(base.$orange-100, 0.1);
  border-radius: 50%;
  margin: 0 auto 24px;
}

.tableWrapper {
  position: relative;
  .contentSelectSize {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;

    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: base.$dark-100;
      margin-right: 16px;
    }

    .ant-select > .ant-select-selector {
      height: 32px;
    }
  }
}