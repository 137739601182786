.graduateLevelContent{
  
  .graduateLevelBox{
    margin-bottom: 24px;
    .graduateLevelHeader{
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      height: 17px;
      .rosso{
        text-decoration: #f0090d;
        color: #f0090d;
      }
      >span{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
      .deleteSpan{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-decoration-line: underline;

      }
      .deleteDiv{
        cursor: pointer;
      }
    }
  }
  .none{
    display: none;
  }
  .addGraduateLevel{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}


