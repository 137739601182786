$title-color: #208AC6;
$black-opacity-50: #00000080;
$light-blue: #f2f8fc;
$green: #69BE7B;

.om-list-item{
    margin: 0 -16px;

    &.selected{
        background: $light-blue;
    }

    .ant-list-split &.ant-list-item{
        padding: 12px 16px;
    }
}


.om{
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;

    &:focus-visible{
        background: red;
    }

    .om-left-section{
        flex: 2;
    }
    .om-right-section{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-left: 10px;

        .om-date{
            color: $black-opacity-50;
            font-size: 12px;
        }

        .om-candidate{
            color: $green;
            font-size: 12px;
            font-weight: 700;
        }

        p{
            margin: 2px 0;
        }
    }

    .om-title{
        margin: 0;
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
        color: $title-color;
        text-decoration: underline;
    }

    .om-location-name{
        margin: 0;
        line-height: 24px;
        font-size: 12px;
        opacity: 50%;
    }

    .om-info-section{
        margin: 8px 0;

        .om-info-line{
            margin: 0;
            line-height: 24px;

            .om-info{
                &:not(:first-child):before{
                    content:" · ";
                    color: $title-color;
                }

                &:nth-child(even){
                    color: $black-opacity-50;
                }
            }
        }
    }

    .om-arrow{
        transform: rotate(-90deg);
        opacity: 50%;
        position: absolute;
        right: 0;
        top: 0;
    }
}