@use "src/variable" as base;

.bodyPadding{
  //padding: 56px 50px;
}

.right-point-button{
  position: relative;
  height: 180px;
  width: 390px;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: rgba(14, 14, 44, 0.15);
}

.right-point-button-inner{
  position: absolute;
  top: 1px;
  left: 1px;
  height: 178px;
  width: 388px;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: white;
}

.left-point-button{
  position: relative;
  height: 180px;
  width: 390px;
  clip-path: polygon(0% 0%, 95% 0, 100% 50%, 95% 100%, 0% 100%);
  background-color: rgba(14, 14, 44, 0.15);
}

.left-point-button-inner{
  position: absolute;
  top: 1px;
  left: 1px;
  height: 178px;
  width: 388px;
  clip-path: polygon(0% 0%, 95% 0, 100% 50%, 95% 100%, 0% 100%);
  background-color: #e9f4fa;
}

.border {
  border: 1px solid rgba(base.$dark-100, 0.1);
}

.question-body {
  width: 700px;
  margin-top: 40px;
}

.padding-40 {
  padding: 40px;
}

.red {
  color: base.$corp2-100 !important;
}

.avatar{
  width: 48px;
  height: 48px;
  background-color: rgba(14, 14, 44, 0.1);
}

.title{
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #0E0E2C;
}

.subtitle{
  font-weight: 400;
  font-size: 14px;
  color: #0E0E2C;
  opacity: 0.5;
}


@media (max-width: 980px)  {
  .customDrawer{
    .bodyPadding{
      padding: 20px 30px;
    }
  }
  .right-point-button{
    height: 135px;
    width: 290px;
  }
  .right-point-button-inner{
    height: 133px;
    width: 288px;
  }
  .left-point-button{
    height: 135px;
    width: 290px;
  }
  .left-point-button-inner{
    height: 133px;
    width: 288px;
  }

  .avatar{
    width: 40px;
    height: 40px;
  }

  .margin-body{

    margin: 40px 0;
  }
}

@media (max-width: 600px) {
  .customDrawer{
    .bodyPadding{
      padding: 20px 20px;
    }
  }
  .right-point-button{
    height: 90px;
    width: 157px;
  }
  .right-point-button-inner{
    height: 88px;
    width: 155px;
  }
  .left-point-button{
    height: 90px;
    width: 157px;
  }
  .left-point-button-inner{
    height: 88px;
    width: 155px;
  }

  .avatar{
    width: 25px;
    height: 25px;
  }
}

