@use "src/variable.scss" as base;

.offerContent {

  label{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .padding-40 {
    padding: 40px;
  }

  .margin-bottom-40 {
    margin-bottom: 40px;
  }

  .margin-left-12 {
    margin-left: 12px;
  }

  .border {
    border: 1px solid rgba(base.$dark-100, 0.1);
  }

  .ant-space {
    width: 100%;
  }


  .red {
    color: base.$corp2-100;
  }

  .grey-50 {
    color: base.$dark-100;
    opacity: 50%;
  }

  .grey-70 {
    color: base.$dark-100;
    opacity: 70%;
  }

  .personalData {

    .ant-select {
      width: 100%;
    }
  }

  .ql-editor{
    min-height:620px;
    font-size: 14px !important;
  }
  .quill > .ql-container > .ql-editor.ql-blank::before{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: base.$dark-100;
    letter-spacing: 0.01em;
    opacity: 0.5;
  }
  .date{
    label{
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: base.$dark-100;
    }
    .ant-picker{
      height: 48px;
      border-radius: 3px;
    }
    .anticon{
      color: base.$dark-100;
    }
  }

  .questionBox{
    margin-bottom: 24px;
    .questionHeader{
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      height: 17px;
      .red{
        color: base.$corp2-100
      }
      >span{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
      .deleteSpan{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-decoration-line: underline;

      }
      .deleteDiv{
        cursor: pointer;
      }
    }
  }
  .none{
    display: none;
  }
  .addQuestion{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.box-admin{
  background: base.$dark-light-95;
  border: 1px solid rgba(14,14,44,0.1);
  width: calc(100% - 80px);
  height: 100%;
  padding: 40px;
}

