.modalProfile {

  &.ant-modal{
    min-width: 800px;
    max-width: 1200px;
    width: 70% !important;
  }

  .ant-modal-wrap {
    display: flex;
    align-content: center;
  }

  .ant-modal-mask {
    display: flex;
  }

  .dislike {
    transform: rotate(180deg);
  }

  .border {
    width: 40px;
    height: 32px;
    box-sizing: border-box;
    border: 2px solid #cfcfd5;
    border-radius: 3px;
  }

  .divider {
    width: 1px;
    height: 32px;
    background: #0E0E2C;
    opacity: 0.1;
    margin-left: 28px;
    margin-right: 28px;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0E0E2C;
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: #C41A43;
  }
}
