@use 'src/variable.scss' as base;

.footer{
  width: 100%;
  min-height: 90px;
  position: relative;
  text-align: center;


  .content-footer{
    @extend .max-width-1200;
    min-height: 50px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
  }

}

@media (max-width: 678px) {
  .footer{
    padding: 0;
    .content-footer{
      padding: 20px 0;
      flex-direction: column;
      justify-content: center;
    }
}
}

@media (max-width: 426px) {
    .footer{
      .content-footer{
        .h1{font-size: 16px;}
        .p{text-align: center; padding: 10px 0; font-size: 12px;}
      }
  }
}

