@use 'src/variable.scss' as base;


.account {
  padding: 0 50px;

  .logout{
    cursor: pointer;
  }
  .margin-right-8 {
    margin-right: 8px;
  }

  .margin-right-22 {
    margin-right: 22px;
  }

  .line-24{
    line-height: 24px;
  }

  .black{
    color: base.$dark-100;
  }


  .title{
    margin-bottom: 45px;
  }

  .content{
    max-width: 800px;
    margin: auto;
  }

  .subContent{
    margin: 40px 0;
  }

  .subTitle{
    margin-bottom: 28px;
  }

  .accountContainer{
    padding: 28px;
    border: 1px solid base.$dark-light-80;
  }

  .companyImg{
    width: 50%;
    img{
        width: 50%;
        height: 25%;
        background-repeat: no-repeat;
        background-size: contain;
        @extend .flex-justify-center;
        border: 1px solid base.$dark-light-80;
      }
    }

  .divider{
    margin: 28px 0;
  }
}

@media (max-width: 500px) {
  .account{
    padding: 0 10px;
  }
}