@use 'src/variable' as base;

.input{
  height: 48px;
}

.ant-modal-content{
  border-radius: 0 !important;
}

.recovery{
  color: base.$dark-100;
  line-height: 17px;
  @extend .weight-600;

  span{
    color: base.$corp-100;
    cursor: pointer;
    line-height: 17px;
    @extend .weight-600;
  }
}
