@use "src/variable" as base;


//---------------------------------
//Content
.containerRecoveryUserPassword{max-width: 1200px; margin: auto;
  .contentRecoveryUserPassword{display: contents; 
  // Immagine
    .image{@extend .flex-justify-center; padding: 0 0px 0px 30px;
      .side-image{width: 100%;}
    }
  //---------------------------------
  // Card login user
    .contentRecoveryPasswordUser{color: base.$dark-100;
      
      // Header
      header{
        // Indietro
        .header_mobile{h1{font-weight: bolder; font-size: 28px; margin-bottom: 8px;}
        .p_back{@extend .weight-600; @extend .size-17;}
      }
      }
      .header_recovery{width: 100%; margin-top: 2px; margin-bottom: 22px;}

      // Main
      main{
          // Paragrafo
          p{font-size: 14px; font-weight: bold; margin-bottom: 8px; margin-top: 0px;}
      }
      // Footer
      footer{margin-top: 25px;}
    }
  }
}

//------------------------
// Responsive

@media (max-width: 768px) {
  .containerRecoveryUserPassword{/*margin-top: 10px;*/ padding: 0 50px; width: 100%;
    .contentRecoveryUserPassword{width: auto;
      .image{display: flex; justify-content: flex-end !important; width: 100%; height: 175px; margin:-45px 0; position: absolute;
        .side-image{display: none}
      }
      .contentRecoveryPasswordUser{width: 100%; margin: 0; padding: 0;}
    }
  }
}

@media (max-width: 625px) {
  .containerRecoveryUserPassword{
    .contentRecoveryUserPassword{
      .side-image{display: none;}
      }
    }
}

@media (max-width: 426px) {
  .containerRecoveryUserPassword{margin: 0 !important; padding: 0px !important;
    .contentRecoveryUserPassword{display: flex; justify-content: flex-start !important; flex-direction: column !important;
      .image{width: 0 !important;
        .side-image{display: none;}
      }
      .contentRecoveryPasswordUser{
        // Header
        header{width: 100%;
          h1{width: 100%; background: #FFFFFF; box-shadow: 0px 1px 3px rgba(14, 14, 44, 0.1); font-weight: 700; font-size: 18px; display: flex; justify-content: center; margin: 0; padding: 10px 0;}
          p{padding: 10px 20px;}
        }
        main{padding: 0 20px;
          .ant-form-item{margin: 5px 0;}
        }
        footer{padding: 20px;}
      }
    }
  }
}
