@use "src/variable" as base;


.radio-button-group{
  display: flex;
  flex-direction: row;

  .padding-16{
    padding: 0 16px;
  }

  .height-100{
    height: 100%;
  }

  .corp-100{
    color: base.$corp-100;
  }

  .dark-50{
    color: base.$dark-light-50;
  }


  .selectedRadio{
    z-index: 100;
    background-color: base.$corp-100 !important;
  }

  .selectedInnerRadio{
    background-color: base.$corp-light-90 !important;
  }


  .right-point-button{
    position: relative;
    height: 40px;
    width: 160px;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0% 0%);
    background-color: base.$dark-light-80;
    z-index: 1;
  }

  .right-point-button-inner{
    position: absolute;
    top: 1px;
    left: 1px;
    height: 38px;
    width: 158px;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0% 0%);
    background-color: white;
  }
  .right-chevron-button{
    margin-left: -9px;
    position: relative;
    height: 40px;
    width: 160px;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    background-color: base.$dark-light-80;
  }

  .right-chevron-button-inner{
    position: absolute;
    top: 1px;
    left: 1px;
    height: 38px;
    width: 158px;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    background-color: white;
  }
}