body{
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0;
    height: 100svh;
}

//*::-webkit-scrollbar{
//    width: 10px;
//    opacity: 0.16;
//}
//*::-webkit-scrollbar-track{
//    background: #14283B16;
//    border-radius: 10px;
//}
//*::-webkit-scrollbar-thumb{
//    background-color: #6EC0FC;
//    border: 2px solid transparent;
//    border-radius: 9px;
//    background-clip: content-box;
//}

/*
@media only screen and (max-width: 500px){
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        transform: translateX(23rem);
    }
}

@media only screen and (max-width: 450px){
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        transform: translateX(21rem);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        width: 300px;
    }
}

@media only screen and (max-width: 400px){
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        transform: translateX(19rem);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        width: 300px;
    }
}
*/
