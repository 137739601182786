@use "src/variable" as base;

.settings-box{
  border: 1px solid rgba(14, 14, 44, 0.10);
  padding: 40px;

  .flex-gap-40{
    column-gap: 40px;
  }

  label{
    font-style: normal;
    font-weight: 600;
    line-height: 17;
  }

  .feedbackSwitch{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    white-space: nowrap;
  }

  .flex-2{
    flex: 2;
  }

  .flex-1{
    flex: 1;
  }

  .feedbackLink{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    label{
      line-height: 1.5;
    }

    input{
      width: 100%;
    }
  }

  .feedback-preview-button{
    position: absolute;
    top: 88px;
    left: 74px;
  }

  .edit-button{
    position: absolute;
    right: -40px;
    top: -1px;
  }

  .link-container{
    position: relative;
    width: calc(100% - 40px);
  }
}