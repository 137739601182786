@use 'src/variable.scss' as base;

.customDrawer {

  .fixed{
    position: fixed;
  }

  .ant-drawer-header{
    border-bottom: none;
    max-width: 1400px;
    margin: 0 auto;
    padding: 16px 50px;
    width: 100%;
  }

  .ant-drawer-close{
    margin: 0 12px 0 6px;
    padding: 0;
  }

  .ant-drawer-title {
    font-weight: 700;
  }

  .ant-drawer-body {
    padding: 0;
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    scroll-behavior: smooth;
    overflow: visible;
  }

  .bodyPadding{
    padding: 56px 50px;
  }

  .menuItem{
    width: fit-content;
    color: base.$dark-100;
  }

  .image-cover{
    width: 124px;
    height: 124px;
    object-fit: cover;
    object-position: 50% 50%;
    img{
      width: 50%;
      height: 25%;
      background-repeat: no-repeat;
      background-size: contain;
      @extend .flex-justify-center;
      border: 1px solid base.$dark-light-80;
    }
  }

  /*.drawer-select{
    width: 150px;
  }*/

  .icon{
    &:hover{
      color: #0E0E2C;
    }
    &:focus{
      color: #0E0E2C;
    }
  }

}
