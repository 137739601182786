@use "src/variable" as base;

.modalNewEmployee{

  .dark-light-50{
    color: base.$dark-light-50;
  }


.content-modal {
  width: 35% !important;
  padding: 40px;
  border-radius: 0px !important;
}

  .modalFooter{
    display: flex;
    justify-content: flex-end;
  }

}
