@use "src/variable" as base;


//---------------------------------
// Content
.login-container-user {
  max-width: 1200px;
  margin: auto;
  flex: 1;
  padding: 0 50px;

  strong{
    color: base.$corp-100;
  }

  .login-content {
    display: flex;
    justify-content: center;

    .platformAdvantages {
      margin-bottom: 40px;
    }

    .platformAdvantagesHeader {
      color: base.$corp-100;
      margin-bottom: 8px;
    }

    .platformAdvantagesDescription, .advantages {
      color: base.$dark-light-20;
    }

    // Immagine
    .image {
      @extend .flex-center;
      max-width: 200px;

      .side-image {
        width: 100%;
      }
    }

    .technicalSupport{
      margin-top: 32px;
      font-size: 10px;
    }

    //---------------------------------
    // Card login user
    .containerLoginUserForm {
      color: base.$dark-100;

      // Header
      header {
        // Indietro
        .header_mobile_user {
          h1 {
            font-weight: bolder;
            font-size: 28px;
            margin-bottom: 8px;
          }

          .user-mobile-logo {
            display: none;
          }

          .p_back {
            @extend .weight-600;
            @extend .size-17;
          }
        }

        // Header login
        .header_login {
          // Colore
          .corp_100 {
            color: base.$corp-100;
          }

          // Colore paragrafo
          p.corp_100 {
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 26px;
          }

          // Paragrafo
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .login_recovery {
        border-top: 1px solid transparentize(base.$dark-100, 0.9);
        border-bottom: 1px solid transparentize(base.$dark-100, 0.9);
        width: 100%;
        margin-top: 2px;
        margin-bottom: 22px;
        // Link
        a {
          color: base.$corp-100;
        }
      }

      // Main
      main {
        // Input
        .inputField {
          // Paragrafo
          p {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 8px;
            margin-top: 0px;
          }

          .ant-input-prefix {
            opacity: 0.6;
          }
        }

        // Sezione
        .section {
          height: 24px;
          margin-bottom: 32px;
          display: flex;
          align-items: center;
          gap: 10px;
          // Paragrafo
          p {
            font-size: 14px;
            font-weight: normal;
          }
        }
      }

      // Footer
      footer {
        margin-top: 25px;
        // Link
        a {
          text-decoration: underline;
          font-size: 14px;
          color: base.$dark-100;
        }
      }
    }
  }
}


//------------------------
// Responsive

@media (max-width: 768px) {
  .login-container-user {
    align-self: baseline;
    margin: unset;

    .advantagesSection {
      display: none;
    }
  }
}

@media only screen and (width: 768px) {
  .login-container-user{
    .image{display: flex; justify-content: flex-end !important; width: 100%; height: 175px; position: absolute; left: 254px; top: -18px;
      .side-image{height: 100%; width: auto;}
    }
    .containerLoginUserForm{width: 584.8px; height: 561px;}
  }
  .ant-col-md-12{max-width: 100% !important;}
}

@media (max-width: 768px) {
  .login-container-user{margin-top: 10px; padding: 0 50px;
    .login-content{width: 100% !important;
      .image{display: flex; justify-content: flex-end !important; width: 100%; height: 175px; position: absolute;
        .side-image{height: 100%; width: auto;}
      }
      .containerLoginUserForm{padding: 0 !important;}
      .ant-col-md-12{max-width: 100% !important;}
    }
  }
}

@media (max-width: 577px){
  .login-container-user{
    .login-content{
      .side-image{display: none;}
    }
  }
}

@media (max-width: 426px) {
  .login-container-user{margin: 0 !important; padding: 0px;
    .login-content{display: flex; justify-content: flex-start !important;
      .containerLoginUserForm{
        // Header
        header{width: 100%;
          .header_mobile_user{
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0 1px 3px rgba(14, 14, 44, 0.1);
            font-weight: 700;
            font-size: 16px;
            display: flex;
            justify-content: center;
            position: relative;
            // Indietro
            .back{
              position: absolute; left: 25px; top: 17px !important;
            }
            .p_back{
              display: none;
            }

            .user-mobile-logo{
              display: block;
              height: 30px;
              position: absolute;
              right: 25px;
              top: 13px;
            }

            h1{
              font-size: 24px
            }
          }
          // Header login
          .header_login{
            // Colore paragrafo
            p.corp_100{display: none;}
            // Paragrafo
            p{font-size: 16px; line-height: 24px; padding: 10px 20px;}
          }
        }
        .login_recovery{
          p{padding: 10px 20px;}
        }
        main{padding: 0 20px;
          .ant-form-item{margin: 5px 0;}
          .section{margin: 20px 0;}
        }
        footer{padding: 0 20px;}
      }
    }
  }
}

@media (max-width: 375px) {
  .login-container-user{
  .login-content{display: flex; justify-content: flex-start !important;}
  }
}

@media (max-width: 320px) {
  .login-container-user{
    .login-content{display: flex; justify-content: flex-start !important;}
  }
}