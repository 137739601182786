$dark-dark-50: #070716;
$dark-100: #0E0E2C;
$dark-light-10: #262641;
$dark-light-20: #3E3E56;
$dark-light-50: #868696;
$dark-light-80: #cfcfd5;
$dark-light-90: #e7e7ea;
$dark-light-95: #f3f3f5;
$dark-light-100: #fff;
$corp-dark-90: #101a3b;
$corp-dark-50: #174c79;
$corp-dark-10: #1e7eb7;
$corp-100: #208ac6;
$corp-light-10: #3696cc;
$corp-light-50: #8fc4e2;
$corp-light-80: #d2e8f4;
$corp-light-90: #e9f3f9;
$corp-light-95: #f4f9fc;
$corp2-dark-90: #200f2e;
$corp2-dark-50: #691438;
$corp2-dark-10: #b21941;
$corp2-100: #c41a43;
$corp2-light-10: #ca3156;
$corp2-light-50: #e18ca1;
$corp2-light-90: #f9e8ec;
$red-100: #fc4853;
$orange-100: #ffa34e;
$yellow-100: #f5d45d;
$green-100: #a6dc61;
$green2-100: #69be7b;
$lilla-100: #6e5ae8;

h1{font-family: 'Merriweather', serif; font-size: 28px; line-height: 1.3;}
.z-index{z-index: 1}
.width-100{width: 100%;}
.width-90{width: 90%;}
.width-60{width: 60%;}
.height-30-px{height: 30px;}
.color-light-blue{background-color: #f2f8fc;}
.white{background-color: white};
.center{text-align: center;}
.inline-block{display: inline-block;}
.flex{display: flex;}
.flex-start{display: flex; justify-content: flex-start;}
.flex-center{display: flex; justify-content: center; align-items: center;}
.flex-end{display: flex; justify-content: flex-end;}
.flex-justify-center{display: flex; justify-content: center;}
.flex-align-center{display: flex; align-items: center;}
.flex-center-between{display: flex; justify-content: space-between; align-items: center;}
.flex-between{display: flex; justify-content: space-between;}
.flex-center-evenly{display: flex; justify-content: space-evenly; align-items: center;}
.flex-column-between{display: flex; flex-direction: column; justify-content: space-between;}
.flex-column{display: flex; flex-direction: column;}
.flex-row{display: flex; flex-direction: row;}
.flex-row-center{display: flex; flex-direction: row; justify-content: center; align-items: center;}
.flex-row-end{display: flex; flex-direction: row; justify-content: flex-end}
.flex-column-center{display: flex; flex-direction: column; justify-content: center;}
.flex-column-end{display: flex; flex-direction: column; justify-content: end;}
.flex-gap{display: inline-flex; flex-wrap: wrap}
.merriWeather{font-family: 'Merriweather', serif;}
.italic{font-style: italic;}
.margin-right-5{margin-right: 5px;}
.margin-right-10{margin-right: 10px;}
.margin-right-20{margin-right: 20px;}
.margin-right-24{margin-right: 24px;}
.margin-left-20{margin-left: 20px;}
.green{color: #69BE7B;}
.black-opacity-50{color: #00000080}
.orange{color: #FFA34E;}
.blue{color: #208AC6;}
.grey{color: #0E0E2C; opacity: 0.5;}
.dark{color: #0E0E2C;}
.dark-light{color: $dark-light-20;}
.bg-grey{background-color: #F3F3F5;}
.margin-0{margin: 0px;}
.margin-5{margin: 5px;}
.margin-10{margin: 10px;}
.margin-16{margin: 16px;}
.margin-top-5{margin-top: 5px;}
.margin-top-10{margin-top: 10px;}
.margin-top-12{margin-top: 12px;}
.margin-top-16{margin-top: 16px;}
.margin-top-20{margin-top: 20px;}
.margin-top-24{margin-top: 24px;}
.margin-top-30{margin-top: 30px;}
.margin-top-54{margin-top: 54px;}
.margin-right-20{margin-right: 20px;}
.margin-left-4{margin-left: 4px;}
.margin-left-5{margin-left: 5px;}
.margin-left-10{margin-left: 10px;}
.margin-left-40{margin-left: 40px;}
.margin-left-55{margin-left: 55px;}
.margin-left-plus{margin-left: 90px;}
.margin-left-minus{margin-left: -90px;}
.margin-bottom-0{margin-bottom: 0;}
.margin-bottom-5{margin-bottom: 5px;}
.margin-bottom-8{margin-bottom: 8px;}
.margin-bottom-10{margin-bottom: 10px;}
.margin-bottom-15{margin-bottom: 15px;}
.margin-bottom-20{margin-bottom: 20px;}
.margin-bottom-25{margin-bottom: 25px;}
.margin-bottom-30{margin-bottom: 30px;}
.padding-left-5{padding-left: 5px;}
.padding-left-10{padding-left: 10px;}
.padding-left-32{padding-left: 32px;}
.padding-left-40{padding-left: 40px;}
.padding-left-70{padding-left: 70px;}
.padding-right-32{padding-right: 32px;}
.padding-right-40{padding-right: 40px;}
.padding-left-right-40{padding: 0 40px;}
.padding-top-bottom-right-40px{padding: 40px 40px 40px 0;}
.padding-top-20{padding-top: 20px;}
.padding-4-12{padding: 4px 12px;}
.padding-top-20{padding-top: 20px;}
.padding-top-30{padding-top: 30px;}
.padding-top-50{padding-top: 50px;}
.padding-bottom-8{padding-bottom: 8px;}
.padding-bottom-18{padding-bottom: 18px;}
.padding-bottom-20{padding-bottom: 20px;}
.padding-bottom-24{padding-bottom: 24px;}
.padding-nobottom-40{padding: 40px 40px 10px;}
.gap-4{gap: 4px;}
.gap-8{gap: 8px;}
.gap-10{gap: 10px;}
.gap-16{gap: 16px;}
.gap-32{gap: 32px;}
.opacity-10{opacity: 10%;}
.opacity-70{opacity: 70%;}
.opacity-30{opacity: 30%;}
.opacity-50{opacity: 50%;}
.weight-700{font-weight: 700;}
.weight-600{font-weight: 600;}
.weight-500{font-weight: 500;}
.weight-400{font-weight: 400;}
.size-32{font-size: 32px;}
.size-30{font-size: 30px;}
.size-29{font-size: 29px;}
.size-28{font-size: 28px;}
.size-25{font-size: 25px;}
.size-24{font-size: 24px;}
.size-22{font-size: 22px;}
.size-20{font-size: 20px;}
.size-18{font-size: 18px;}
.size-17{font-size: 18px;}
.size-16{font-size: 16px;}
.size-15{font-size: 15px;}
.size-14{font-size: 14px;}
.size-13{font-size: 13px;}
.size-12{font-size: 12px;}
.size-10{font-size: 10px;}
.max-width-1200{max-width: 1200px; margin: 0 auto;}
.pointer {cursor: pointer;}
.defaul {cursor: default;}
.underlined{text-decoration: underline;}
.bgCompany{background: linear-gradient(180deg, rgba(196, 26, 67, 0.10) 0%, $dark-light-100 100%);}
.bgUser{background: linear-gradient(180deg, rgba(32,138,198, 0.10) 0%, $dark-light-100 100%);}
.half-image{width: 50%;}
.border-solid-grey{border: 1px solid grey;}
.border-radius-0{border-radius: 0px;}
.border-radius-3{border-radius: 3px;}
.border-radius-25{border-radius: 25px;}
.border-grey{border: 1px solid rgba(14, 14, 44, 0.2);}
.padding-4{padding: 4px;}
.padding-5{padding: 5px;}
.padding-10{padding: 10px;}
.padding-15{padding: 15px;}
.padding-18{padding: 18px;}
.padding-20{padding: 20px;}
.padding-24{padding: 24px;}
.padding-right-20{padding-right: 20px;}
.padding-buttom-30{padding-bottom: 30px;}
.padding-20-no-top{padding-top: 0px; padding-bottom: 20px; padding-left: 20px; padding-right: 20px;}
.padding-20-no-bottom{padding-top: 20px; padding-bottom: 0px; padding-left: 20px; padding-right: 20px;}
.ant-spin-nested-loading{width: 100% !important; height: 100% !important;}
.ant-spin-nested-loading .ant-spin-container{width: 100% !important; height: 100% !important;}
.customBreadcrumb {margin: 24px 0 32px 0; color: $dark-100; opacity: 50%;}
.line-height-20{line-height: 20px;}
.line-height-30{line-height: 30px;}
.display-none{display: none !important;}
.noLogo{height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; background-color: #F3F3F5; border: 1px solid #0E0E2C1A; opacity: 50%;}
.dropdown{
    &.ant-select-dropdown{padding: 5px 0px; width: 225px;}
    &.ant-select-focused .ant-select-selector{box-shadow: 0 0 0 1px white !important;}
    &.ant-select-dropdown .ant-select-item-option-grouped{padding-inline-start: 0 !important;}
    &.ant-select-dropdown .ant-select-item{padding: 0 5px !important; border-radius: 0 !important;}
    &.ant-select-dropdown .ant-select-item-option-content {padding: 5px 14px 0 14px;}
    &.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: whitesmoke !important;}
}
/*.upload-user{
    &.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container{margin-top: 40px;}
}*/
