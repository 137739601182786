@use "src/variable" as base;

.detailCompany {

  .dividerDot > div + div:before{
    content: "\00a0  ·  \00a0";
  }

  .maxWidth {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
  }


  .ease-list-in {
    animation: moveIn 1s;
  }

  .ease-list-out {
    animation: moveOut 1s;
  }

  .animation-none {
    width: 100%;
    animation: none;
  }

}

@keyframes moveIn {
  from {
    width: 100%
  }
  to {
    width: 60%
  }
}

@keyframes moveOut {
  from {
    width: 60%
  }
  to {
    width: 100%
  }
}
