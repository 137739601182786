@use "src/variable" as v;

.activated-container-content-error {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  h1 {
    color: v.$green2-100;
    font-weight: bolder;
    font-size: 28px;
    margin-bottom: 16px;
    text-align: center;
  }
}