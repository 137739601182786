@use 'src/variable.scss' as base;


.emailModal {

  .padding-right-8 {
    padding-right: 8px;
  }

  .padding-bottom-8{
    padding-bottom: 8px;
  }

  .padding-bottom-30{
    padding-bottom: 30px;
  }


  .ant-modal-content {
    padding: 40px;
    border-radius: 0;
  }

}
