.myCandidature {
  padding: 0 50px;

  .dividerDot > div + div:before{
    content: "\00a0  ·  \00a0";
  }

  .dividerDot > div + div{
    margin-left: 5px;
  }

  .ease-list-in {
    animation: moveIn 1s;
  }

  .ease-list-out {
    animation: moveOut 1s;
  }

  .animation-none {
    width: 100%;
    animation: none;
  }

  .margin-bottom-24{
    margin-bottom: 24px;
  }

  .ant-list-bordered {
    border-radius: 0;
  }

  .backgroundStatePending{
    font-weight: 600;
    background-color: rgba(245, 212, 93, 0.4);
    color: #0E0E2C;
    font-size: 14px;
    padding: 4px 8px;
  }

  .backgroundStateInterview{
    font-weight: 600;
    background-color: rgba(245, 212, 93, 0.2);
    color: #0E0E2C;
    font-size: 14px;
    padding: 4px 8px;
  }

  .backgroundStatePreselected{
    font-weight: 700;
    background-color: rgba(105, 190, 123, 0.2);
    color: #0E0E2C;
    font-size: 14px;
    padding: 4px 8px;
  }

  .backgroundStateSelected{
    font-weight: 700;
    background-color: rgba(105, 190, 123, 0.2);
    color: #69BE7B;
    font-size: 14px;
    padding: 4px 8px;
  }

  .backgroundStateReject{
    font-weight: 600;
    background-color: rgba(14, 14, 44, 0.1);
    color: #0E0E2C;
    font-size: 14px;
    padding: 4px 8px;
  }

}

@keyframes moveIn {
  from {
    width: 100%
  }
  to {
    width: 60%
  }
}

@keyframes moveOut {
  from {
    width: 60%
  }
  to {
    width: 100%
  }
}

@media (max-width: 500px) {
  .myCandidature{
    padding: 0 10px;
  }
}