@use "src/variable" as base;


.offersList {
  position: relative;


  .icon {
    &:hover {
      color: #0E0E2C;
    }

    &:focus {
      color: #0E0E2C;
    }
  }

  .action-icon{
    padding: 0 10px;
  }

  .close-candidature-button{

    &:hover .close-candidature-text{
      color: #0E0E2C;
    }
    &:hover svg{
      color: #0E0E2C;
    }

    .close-candidature-text{
      margin: 0;
      line-height: 20px;
      font-size: 13px;
      color: #868696;
    }
  }



  .contentSelectSize {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;

    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: base.$dark-100;
      margin-right: 16px;
    }

    .ant-select > .ant-select-selector {
      height: 32px;
    }
  }

  .tableStyleLow {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #868696;
  }

  .bozza {
    padding: 5px 10px;
    background: rgba(14, 14, 44, 0.2);
    color: #FFFFFF;
  }

  .cellContent {
    display: flex;
    align-items: center;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .margin-right-8 {
    margin-right: 8px;
  }

  .black-text {
    color: base.$dark-100;
  }

  .green-text {
    color: base.$green2-100;
  }

  .green-background {
    background-color: base.$green2-100;
  }

  .grey-background {
    background-color: base.$dark-100;
    opacity: 50%;
  }

  .orange-background {
    background-color: base.$orange-100;
  }


  &.ant-list {
    background-color: #FFFFFF;
  }

  &.ant-list-item {
    padding-inline: 16px;
  }

  &.ant-list-bordered {
    border-radius: 0;
  }

  .blue {
    cursor: pointer;
    color: base.$corp-100;
  }


  &.dashboardPage {
    .ant-list-item > :first-child {
      width: 25%;
    }

    .ant-list-item > :nth-child(n+2) {
      width: 20%;
    }

    .ant-list-item > :last-child {
      width: 10%;
    }
  }

  &.offersPage {
    .ant-list-item > :nth-child(n) {
      width: 10%;
    }

    .ant-list-item > :first-child,
    .ant-list-item > :nth-child(9),
    .ant-list-item > :last-child {
      width: 5%;
    }

    .ant-list-item > :nth-child(2) {
      width: 25%;
    }
  }

  .tag {
    width: fit-content;
    padding: 5px 10px;
    color: #FFFFFF;
  }

  .noOffers {
    margin: 44px 0;
  }

  .noOffersIcon {
    width: 100px;
    height: 100px;
    background-color: rgba(base.$orange-100, 0.1);
    border-radius: 50%;
    margin: 0 auto 24px;
  }


  .ant-table-content {
    @extend .border-grey;
  }

  &.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  .ant-select-dropdown {
    border-radius: 0;
  }
}

/*
*/

.siteTable {
  &.ant-table-wrapper .ant-table {
    @extend .border-grey;
    border-radius: 0;
  }

  &.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
}

.isEmployeeActive {
  background: #A6DC61;
}

