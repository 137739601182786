@use "src/variable" as base;

.cardContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 32px 0 0 0;
  width: 100%;
  gap: 40px;
  transition: 0.5s;
}
.dashboardCard{
  max-width: 350px;
  position: relative;
}

.dashboardCsvButton{
  position: absolute;
  top:0;
  right: 0;
}