@use "src/variable.scss" as base;

.confirm-avatar{
  width: 60px;
  height: 60px;
  background-color: #A6DC61;
  border-radius: 100%;
  filter: drop-shadow(0px 4px 4px rgba(14, 14, 44, 0.1));
}

.title{
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: base.$dark-100;
}

.subtitle{
  font-weight: 400;
  font-size: 18px;
  color: base.$dark-100;
}

.description{
  font-size: 16px;
  font-weight: 400;
  color: base.$dark-100;
  opacity: 0.5;
}

@media (max-width: 980px) {
  .title{
    font-size: 16px;
  }
  .subtitle{
    font-size: 16px;
  }
  .description{
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .title{
    font-size: 12px;
  }
  .subtitle{
    font-size: 12px;
  }
  .description{
    font-size: 12px;
  }
}


