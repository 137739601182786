@use 'src/variable.scss' as base;

.dividerNotificationDot span + span:before{
  content: "\00a0 ·\00a0";
}

.margin-left-18 {
  margin-left: 18px;
}

.margin-left-8 {
  margin-left: 8px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-16 {
  line-height: 16px;
}

.logo {
  height: 32px;
}

.blue{
  color: base.$corp-100;
}

.ant-popover.myAccount,
.ant-popover.notifyPopover {
  top: 58px !important;
}

.landingTopbar {
  width: 100%;
  min-height: 100px;

  .topbarContent {
    @extend .max-width-1200;
    @extend .flex-center-between;
    height: 100%;
    padding: 0 50px;
  }
}

.userTopbar {
  background: linear-gradient(180deg, #208AC6 0%, #167FBC 100%);
}

.companyTopbar {
  background: linear-gradient(180deg, rgba(196, 26, 67, 1) 35%, rgba(187, 25, 66, 1) 100%);
}

.adminTopbar {
  background: linear-gradient(0deg, rgba(14, 14, 44, 0.8), rgba(14, 14, 44, 0.8)), linear-gradient(180deg, #C41A43 0%, #208AC6 100%);
}

.companyTopbar,
.userTopbar,
.adminTopbar {

  .blueCircle {
    width: 10px;
    min-width: 10px;
    height: 10px;
    background: base.$corp-100;
    border-radius: 50%;
  }

  .icon {
    &:hover {
      color: #0E0E2C;
    }

    &:focus {
      color: #0E0E2C;
    }
  }

  .sidePadding {
    padding: 0 50px;
    margin: auto;
  }

  .topbarContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    max-width: 1200px;
    margin: auto;
  }

  .menuContainer {
    height: 100%;
    width: 100%;

    .active {
      border-bottom: 4px solid white;
      height: calc(100% - 4px);
      display: flex;
      .text{
        margin: 0 0 -6px 0;
      }
    }

    .active-popup {
      border-bottom: none !important;
      .text{
        margin:0;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      margin: 0;
      padding: 0;

      li {
        @extend .size-15;
        @extend .weight-500;
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 12px;
        color: #ffffff;

        a {
          display: flex;
          align-items: center;
          height: 100%;
          color: #ffffff;
          text-decoration: none;
        }

        a:hover,
        .hover-opacity-70:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .notifyPopover {
    width: 400px;

    .ant-popover-arrow {
      display: none;
    }

    .notifyPage {
      color: base.$corp-100;
    }

    .ant-popover-inner {
      border-radius: 0;
      padding: 0;
    }

    .notifyPadding {
      padding: 18px 24px;
    }

    .border-bottom {
      border-bottom: 1px solid rgba(14, 14, 44, 0.1);
    }

    .header {
      box-shadow: 0 2px 4px rgba(14, 14, 44, 0.1);
    }

    .notifyIcon {
      width: 40px;
      height: 40px;
      background-color: rgba(105, 190, 123, 0.5);
    }

    .end-border-bottom {
      position: absolute;
      bottom: 8px;
      width: 100%;
      border-bottom: 1px solid rgba(14, 14, 44, 0.1);
    }
  }

  .myAccount {
    width: 225px;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 0;
      padding: 0;
    }

    .ant-divider-horizontal {
      width: unset;
      min-width: unset;
      max-width: 100%;
      margin: 0;
    }

    .accountPadding {
      padding: 8px 0;
    }

    .padding-8 {
      padding: 8px;
    }

    .spacing:hover {
      background-color: rgba(32, 138, 198, 0.1);
    }
  }
}


@media (max-width: 970px) {
  .menuContainer ul {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .landingTopbar {
    .topbarContent {
      .button {
        display: none;
      }
    }
  }

  .companyTopbar .sidePadding, .userTopbar .sidePadding, .adminTopbar .sidePadding  {
    padding: 0 20px;
  }
}

@media (max-width: 426px) {
  .userTopbar {
    background-color: #C41A43;
  }
}
