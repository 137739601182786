@use "src/variable" as base;

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .bgCompany {
    background: linear-gradient(180deg, rgba(196, 26, 67, 0.10) 0%, #fff 100%);
  }

  .bgUser {
    background: linear-gradient(180deg, rgba(32, 138, 198, 0.10) 0%, #fff 100%);
  }

  .content {
    flex: 1;

    &.landing {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
}

.loader {
  height: 100%;
  width: 100%;
}

.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 26px;
  //cursor: pointer;

  p {
    font-size: 14px;
  }
}


//----------------------
// Responsive

@media (max-width: 767px) {
  .content {
    .container {
      display: flex;
      justify-content: center !important;
    }
  }

  .login-container .login-content .image {
    display: flex;
    justify-content: flex-end !important;
    width: 100%;
    height: 175px;
    position: absolute;
    left: 0px;
    top: -18px;
  }
}
