@use "src/variable" as base;


.actions {
  min-width: 225px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 0;
    padding: 0;
  }

  .actionsContent {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
  }

  .element {
    padding: 4px 24px;
  }

  .element:hover {
    background-color: base.$corp-light-90;
  }

  .ant-divider-horizontal {
    width: unset;
    min-width: unset;
    max-width: 100%;
    margin: 0 8px;
  }
}

.offerProfile {
  padding: 0 50px;
  
.icon{
  &:hover{
    color: #0E0E2C;
  }
  &:focus{
    color: #0E0E2C;
  }
}

    .ant-select > .ant-select-selector {
      height: 32px !important;
      background: #E9F3F9;
      box-shadow: inset 0px -1px 0px #D2E8F4;
      border-radius: 3px;
      border: none;
    }

    .ant-select-selection-item {
      font-size: 14px;
      font-weight: 600;
    }


  .page-size-changer {
    position: absolute;
    bottom: 16px;
  }


  .padding-8 {
    padding: 8px;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }

  .margin-bottom-16 {
    margin-bottom: 16px;
  }

  .margin-right-24 {
    margin-right: 24px;
  }

  .margin-right-16 {
    margin-right: 16px;
  }

  .margin-left-5 {
    margin-left: 5px;
  }

  .blue{
    color: base.$corp-100;
  }

  .grey {
    color: base.$dark-light-50;
  }


  .noCandidates{
    padding: 55px 0;
  }

  .offerInfo {
    border-radius: 0;
    border: 1px solid rgba(14, 14, 44, 0.1);
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  .card {
    min-height: 120px;
    border-radius: 0;
    border: 1px solid #0E0E2C1A;
  }

  .offerFilterBox .ant-btn {
    padding: 10px 12px;
    height: 40px;
    width: 70px;
    border: 1px solid base.$dark-light-80;
    border-radius: 0;
  }

  .offerFilterBox .selected {
    background-color: base.$corp-light-90;
    border: 1px solid base.$corp-100;
  }

  .offerDetailTable {
    .ant-table-content {
      border: 1px solid base.$dark-light-80;
    }

    .ant-table-thead .ant-table-cell::before {
      content: none !important;
    }

    .ant-table-container table > thead > tr:first-child th:first-child,
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-start-start-radius: 0;
      border-start-end-radius: 0;
    }


    .ant-table-row .ant-table-cell:last-child {
      z-index: 10;
    }

    .ant-btn {
      border: 1px solid base.$dark-light-90;
      border-radius: 3px;
      width: 40px;
      padding: 0;

      &.borderless {
        float: right;
        border: none;
        box-shadow: none;
      }
    }

    .dot{
      height: 8px;
      width: 8px;
      background-color: #208AC6;
      border-radius: 50%;
    }
  }

  .page-size-changer {
    .tableSelect {
      min-width: 80px;

      .ant-select-selector {
        height: 32px;
        background: #E9F3F9;
        box-shadow: inset 0px -1px 0px #D2E8F4;
        border-radius: 3px;
        border: none;
      }

      .ant-select-selection-item {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .ant-pagination {
    display: flex;

    li {
      margin: 0 0 0 -1px;
      border: 1px solid base.$dark-light-80;
    }

    li:first-child button,
    li:last-child button {
      border-radius: 0;
    }

    .ant-pagination-prev {
      border-radius: 3px 0 0 3px;
    }

    .ant-pagination-next {
      border-radius: 0 3px 3px 0;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border-radius: 0;
    }

    .ant-pagination-item {
      border-radius: 0;

      a {
        color: base.$corp-100;
      }
    }

    .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
    .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
      color: base.$corp-100;
    }

    .ant-pagination-item-active {
      border-color: base.$dark-light-80;

      a {
        color: base.$dark-100;
      }
    }
  }
}





