@use "src/variable" as base;

.userOffert {

  .dividerDot > :not(:first-child)::before{
    content: "\00a0  ·  \00a0";
  }

  .maxWidth {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
  }

  .filterBar {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 20px 10px 10px 0;

    & > div {
      padding: 0 10px;
      width: 100%;
    }
  }

  .input-offert {
    height: 38px;
  }

  .select-offert {
    height: 28px !important;

    .ant-select > .ant-select-selector {
      height: 40px;
    }

    .icon {
      margin-top: -3px;
    }

    .text {
      margin-top: -3px;
    }
  }

  .switch-offert {
    @extend .flex-center
  }

  .btn-user {
    .ant-btn {
      height: 38px;
    }
  }

  .list-margin {
    margin: 40px 0;
  }

  .ant-divider {
    margin: 0;
  }

  .title {
    margin-bottom: 16px;
  }

  .ant-list-item {
    padding-inline: 16px;
  }

  .ant-list-bordered {
    border-radius: 0;
  }

  .companyName {
    text-decoration: underline;
  }

  .imgBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0E0E2C1A;
  }

  .loadMore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }

  .loadMoreButton {
    width: 320px;
    color: base.$corp-100;
  }


  .icon {
    &:hover {
      color: #0E0E2C;
    }

    &:focus {
      color: #0E0E2C;
    }
  }

  .icon-heart {
    &:hover {
      color: #208AC6;
    }

    &:focus {
      color: #208AC6;
    }

    &:active {
      color: #208AC6;
    }
  }


  .arrow-icon {
    transform: rotate(-90deg)
  }

  .ease-list-in {
    animation: moveIn 1s;
  }

  .color-light-blue {
    background-color: #f2f8fc;
  }

  .ease-list-out {
    animation: moveOut 1s;
  }

  .animation-none {
    width: 100%;
    animation: none;
  }

}

@keyframes moveIn {
  from {
    width: 100%
  }
  to {
    width: 60%
  }
}

@keyframes moveOut {
  from {
    width: 60%
  }
  to {
    width: 100%
  }
}

@media (max-width: 500px) {
  .userOffert{
    .filterBar{
      display: flex;
      flex-direction: column;
      .input-dashboard{
        width: 100%;
        height: 45px;
        padding-bottom: 20px;
        margin-left: -5px;
      }
      .select-dashboard{
        display: none;
      }
      .switch-dashboard{
        display: none;
      }
      .container-button{
        width: 100%;
        margin-left: -5px;
      }
    }
  }
}

@media (max-width: 990px) {
  .userOffert{
    .filterBar{
      .switch-dashboard{
        display: none;
      }
    }
  }
}

@media (max-width: 780px) {
  .userOffert{
    .filterBar{
      .switch-dashboard{
        display: none;
      }
      .select-dashboard{
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .userOffert {
    .maxWidth {
      padding: 0 10px;
    }
  }
}