@use 'src/variable.scss' as base;


.notification {
  padding: 0 50px;

  .margin-right-22 {
    margin-right: 22px;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .blue {
    color: base.$corp-100;
  }

  .black{
    color: base.$dark-100;
  }


  .title {
    padding-bottom: 24px;
  }

  .reloadButton .text {
    margin-left: 5px;
  }

  .ant-list-header {
    padding: 12px 24px;
    border-block-start: 1px solid rgba(5, 5, 5, 0.06);
  }

  .notifyIcon {
    width: 40px;
    height: 40px;
    background-color: rgba(105, 190, 123, 0.5);
  }

  .blueCircle {
    width: 10px;
    min-width: 10px;
    height: 10px;
    background: base.$corp-100;
    border-radius: 50%;
  }


  .ant-list-item > :nth-child(1) {
    width: 5%;
  }

  .ant-list-item > :nth-child(2) {
    width: 20%;
  }

  .ant-list-item > :nth-child(3) {
    width: 50%;
  }

  .ant-list-item > :nth-child(4) {
    width: 10%;
  }

  .ant-list-item > :nth-child(5) {
    width: 5%;
  }

  .ant-list-items > :last-child {
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  }


  .ant-list{
    border-radius: 0;
  }

  .noNotification {
    margin: 44px 0;
  }

  .noNotificationIcon {
    width: 100px;
    height: 100px;
    background-color: rgba(base.$orange-100, 0.1);
    border-radius: 50%;
    margin: 0 auto 24px;
  }
}