@use "src/variable.scss" as base;


.companyContent {

  .isEmployeeNotActive{
    background-color: rgba(14, 14, 44, 0.1);
    padding: 5px;
  }

  .icon{
    &:hover{
      color: #0E0E2C;
    }
    &:focus{
      color: #0E0E2C;
    }
  }

  .margin-left-16{
    margin-left: 16px;
  }

  .margin-left-12 {
    margin-left: 12px;
  }

  .margin-left-8 {
    margin-left: 8px;
  }

  .margin-left-5{
    margin-left: 5px;
  }

  .margin-bottom-8 {
    margin-bottom: 8px;
  }

  .negative-margin-bottom-3 {
    margin-bottom: -3px;
  }

  .padding-40 {
    padding: 40px;
  }

  .margin-bottom-40 {
    margin-bottom: 40px;
  }

  .margin-left-12 {
    margin-left: 12px;
  }


  .border {
    border: 1px solid rgba(base.$dark-100, 0.1);
  }


  .line-height-28 {
    line-height: 28px;
  }

  .red {
    color: base.$corp2-100 !important;
  }

  .blue {
    color: base.$corp-100;
  }

  .grey-70 {
    color: base.$dark-100;
    opacity: 70%;
  }

  .grey-50 {
    color: base.$dark-light-50;
  }


  .ant-space {
    width: 100%;
  }

  .alert {
    padding: 16px 24px;
    background-color: rgba(base.$corp-100, 0.1);
    border: 1px solid rgba(base.$corp-100, 0.5);
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper{
      width: 20%;
    }

  .companyData {

  

    .upload{
      display: flex;
      width: 100%;

      .ant-upload-wrapper{
        width: 124px !important;
      }
      .text{
        display: flex;
        align-items: center;
      }
    }

    .logo{
      width: 124px;
      height: 124px;
      background-color: rgba(base.$dark-100, 0.1);
    }

    .ant-select {
      width: 50%;
    }
  }

}

.ant-form-item-has-error{
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
    border: 1px solid red;
  }
  .uploadSvg svg{
    color: red;
  }
}

.opacity-7{
  opacity: 0.7;
}

.italic{
  font-style: italic;
}