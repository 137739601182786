@use "src/variable" as v;

.activated-container-content-success{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1{
    color: v.$green2-100;
    font-weight: bolder;
    font-size: 28px;
    margin-bottom: 16px;
    text-align: center;
  }

  h2{
    color: v.$dark-100;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
  }

  p{
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    opacity: 0.7;
  }
}

