@use "src/variable" as base;


//---------------------------------
// Content
.registration-user-container {
  max-width: 1200px;
  margin: auto;
  padding: 0 50px;

  strong{
    color: base.$corp-100;
  }

  .registration-content {
    display: flex;
    justify-content: center;

    .platformAdvantages {
      margin-bottom: 40px;
    }

    .platformAdvantagesHeader {
      color: base.$corp-100;
      margin-bottom: 8px;
    }

    .platformAdvantagesDescription, .advantages {
      color: base.$dark-light-20;
    }

    // Immagine
    .image {

      .side-image {
        max-width: 200px;
      }
    }

    .technicalSupport{
      margin-top: 32px;
      font-size: 10px;
    }

    //---------------------------------
    // Card login user
    .containerRegistrationUserForm {
      color: base.$dark-100;

      // Header
      header {
        // Indietro
        .header_mobile {

          .user-mobile-logo{
            display: none;
          }

          h1 {
            font-weight: bolder;
            font-size: 28px;
            margin-bottom: 8px;
          }

          .p_back {
            @extend .weight-600;
            @extend .size-17;
          }
        }

        // Header login
        .header_registration {
          // Colore
          .corp_100 {
            color: base.$corp-100;
          }

          // Colore paragrafo
          p.corp_100 {
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 26px;
          }

          // Paragrafo
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .registration_recovery {
        border-top: 1px solid transparentize(base.$dark-100, 0.9);
        border-bottom: 1px solid transparentize(base.$dark-100, 0.9);
        width: 100%;
        margin-top: 22px;
        margin-bottom: 22px;
        // Link
        a {
          color: base.$corp-100;
        }
      }

      // Main
      main {
        h1.corp_100 {
          color: base.$corp-100;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          margin-top: 0;
          margin-bottom: 26px;
        }

        // Input
        .required::before {
          content: "*";
          display: inline-block;
          color: base.$corp2-100;
          margin-right: 5px;
        }

        .input {
          margin-bottom: 4px;
        }

        .nameSurname {
          width: 100%;
          display: flex;
          gap: 24px;

          div {
            width: 100%;
          }
        }

        .generalConditions {
          .checkbox{
            margin-right: 8px;
          }
          p {
            font-weight: normal;
            font-size: 14px;

            &::before {
              font-weight: bold;
            }

            a {
              font-weight: bold;
              text-decoration: underline;
              color: base.$dark-100;
            }
          }
        }
      }

      // Footer
      footer {
        margin-top: 40px;

        .loginAsCompany {
          h1 {
            margin-bottom: 0;
          }

          p {
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .save {
          margin-bottom: 48px;
        }
      }
    }
  }
}


//------------------------
// Responsive

@media only screen and (width: 768px) {
  .registration-user-container {
    .image {
      display: flex;
      justify-content: flex-end !important;
      width: 100%;
      height: 175px;
      position: absolute;

      .side-image {
        height: 100%;
        width: auto;
      }
    }

    .containerRegistrationUserForm {
      width: 584.8px;
    }
  }
  .ant-col-md-12 {
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .registration-user-container {
    margin-top: 10px;

    .registration-content {
      width: 100% !important;

      .advantagesSection {
        display: none;
      }

      .image {
        display: flex;
        justify-content: flex-end !important;
        width: 100%;
        height: 175px;
        position: absolute;

        .side-image {
          height: 100%;
          width: auto;
        }
      }

      .containerRegistrationUserForm {
        padding: 0 !important;
      }

      .ant-col-md-12 {
        max-width: 100% !important;
      }
    }
  }
}

@media (max-width: 426px) {
  .registration-user-container {
    margin: 0 !important;
    padding: 0px;

    .registration-content {
      display: flex;
      justify-content: flex-start !important;

      .side-image {
        display: none;
      }

      .containerRegistrationUserForm {
        // Header
        header {
          width: 100%;

          .header_mobile {
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(14, 14, 44, 0.1);
            font-weight: 700;
            font-size: 16px;
            display: flex;
            justify-content: center;

            h1 {
              font-size: 24px;
            }

            .user-mobile-logo{
              display: block;
              height: 30px;
              position: absolute;
              right: 25px;
              top: 13px;
            }

            // Indietro
            .back {
              position: absolute;
              left: 25px;
              top: 17px !important;
            }

            .p_back {
              display: none;
            }
          }

          // Header registration
          .header_registration {
            // Colore paragrafo
            p.corp_100 {
              display: none;
            }

            // Paragrafo
            p {
              font-size: 14px;
              line-height: 24px;
              padding: 10px 20px;
            }
          }
        }

        .registration_recovery {
          p {
            padding: 10px 20px;
          }
        }

        main {
          padding: 0 20px;

          .ant-form-item {
            margin: 5px 0;
          }

          .section {
            margin: 20px 0;
          }
        }

        footer {
          padding: 0 20px;
          margin-bottom: 20px;

          .loginAsCompany {
            h1 {
              font-size: 20px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .registration-user-container {
    .registration-content {
      display: flex;
      justify-content: flex-start !important;
    }
  }
}

@media (max-width: 320px) {
  .registration-user-container {
    .registration-content {
      display: flex;
      justify-content: flex-start !important;

      .containerRegistrationUserForm {
        header {
          .header_mobile {
            h1 {
              font-size: 20px;
            }

            .user-mobile-logo {
              top: 9px;
            }
          }
        }
      }
    }
  }

}