.circle{
  background: rgba(32, 138, 198, 0.2);
  border-radius: 28px;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-weight: 700;
  margin-right: 10px;
}

.profile-circle{
  background: linear-gradient(0deg, rgba(14, 14, 44, 0.1), rgba(14, 14, 44, 0.1)), #FFFFFF;
  width: 80px;
  height: 80px;
  text-align: center;
  font-weight: 700;
  border-radius: 50%;
  line-height: 80px;
  margin-right: 20px;
  margin-left: 20px;
}

.padding{
  padding: 10px;
  padding-top: 50px;
}

.align-point{
  display: flex;
  align-items: self-end;
}

.margin-text-left{
  margin-left: 34px;
}

.text-align-center{
  text-align: center;
}

.border-div{
  margin-top: 65px;
  border: 1px solid #f0f0f0;
}

.ab{
  margin-top: -40px;
}

.bor{
  padding: 0 5px;
  background-color: #FFFFFF;
}
