@use "src/variable" as base;

.userDashboard {

    .ant-row{
        justify-content: space-around;
    }

    .maxWidth {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 50px;
    }

    .bgImage {
        height: 459px;
        background: url("../../assets/image/bg/user_dashboard_background.png") no-repeat center top;
        background-size: cover;
    }

    .margin-bottom-24 {
        margin-bottom: 24px;
    }

    .blue {
        color: base.$corp-100;
    }

    .input-dashboard{
        width: 100%;
    }

    .topPadding {
        padding-top: 72px;
    }

    .list-margin {
        margin: -80px 0 40px 0;
    }

    .title {
        margin-bottom: 16px;
    }

    .filterBar {
        background: #FFFFFF;
        padding: 32px;
        box-shadow: 0 0 0 1px rgba(14, 14, 44, 0.1);

        & > div {
            padding: 0 10px;
            width: 100%;
        }
    }

    .input-dashboard{
        height: 48px;
    }

    .select-dashboard{
        width: 100%;
        height: 48px;
        .select-prefix-dashboard{
            width: 100%;
        }
    }

    .select-dashboard-when{
        width: 100%;
        height: 48px;
        .select-prefix-dashboard-when{
            width: 100%;
        }
    }

    .switch-dashboard{
        @extend .flex-center
    }

    .button-dashboard .button-user-dashboard{
        width: 100%;
    }
    
    .list-dashboard{
        background-color: #FFFFFF;
    }

    .ant-list-item {
        padding-inline: 16px;
    }

    .ant-list-item > :first-child {
        height: 60px;
        width: 70px;
        background-size: cover;
    }

    .ant-list-item > :nth-child(2){
        width: 150px;
    }

    .ant-list-item > :nth-child(3),
    .ant-list-item > :nth-child(4),
    .ant-list-item > :nth-child(5) {
        width: 150px;
    }

    .ant-list-item > :last-child {
        width: 110px;
    }

    .ant-list-bordered {
        border-radius: 0;
    }

    .imgBorder {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #0E0E2C1A;
    }

    .pointer:hover {
        cursor: pointer;
    }

    .userIcon {
        padding-right: 8px;
    }

    .cards {
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        gap: 1rem;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: auto auto;
    }

    .grid-item {
        padding: 20px;
        text-align: center;
        border: 1px solid #E7E7EA;
        margin: 0 -1px -1px 0;
    }

    .switch-button{
        display: unset;
    }
}


@media (max-width: 1199px) {
    .userDashboard .filterBar{
        padding: 20px;
    }
    .userDashboard .input-dashboard{
        width: 100%;
    }

    .ant-row{
        justify-content: space-between !important;
    }
}

@media (max-width: 992px) {
    .userDashboard .switch-dashboard{
        display: none;
    }
    .userDashboard .imgBorder{
        margin-right: 10px;
    }
}

@media (max-width: 885px) {
    .userDashboard .switch-dashboard{
        display: none;
    }

    .ant-col .flex-center .ant-col-xs-24 .ant-col-md-8 .ant-col-lg-11 .ant-col-xl-12 .css-dev-only-do-not-override-1ykttpv .switch-dashboard{
        display: none;
    }
}

@media (max-width: 768px) {

    .userDashboard .input-dashboard{
        width: 100%;
    }
    .userDashboard .filterBar {
        padding-left: 15px;
        padding-right: 15px;
    }
    .userDashboard .topPadding{padding-top: 20px;}
    .userDashboard .maxWidth{padding: 0 20px;}
    .userDashboard .bgImage{height: 450px;}
    .userDashboard .title{font-size: 20px;}
    .userDashboard .sub-title{font-size: 14px;}
}

@media (max-width: 767px) {
    .userDashboard .select-dashboard{
        display: none;
    }
}

@media (max-width: 476px) {
    .userDashboard .list-margin{
        margin-top: 20px;
    }
    .userDashboard .bgImage{height: 350px;}

    .ant-list .ant-list-item{
        flex-wrap: wrap;
    }
    .userDashboard .list-dashboard{
        .imgBorder{
            position: absolute;
            right: 0;
            background-size: cover;
        }
        .title-companyname{
            .companyName{font-size: 14px}
            font-size: 12px;
        }
        .title-companyname_without_image{
            .companyName{font-size: 14px}
            font-size: 12px;
        }
        .mode-remote{
            font-size: 12px;
        }
        .location-commit{
            font-size: 12px;
        }
        .icon-user {
            font-size: 14px;
            .userIcon{
                width: 15px;
                height: 15px;
            }
        }
        .date{
            font-size: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: start;
        }
    }
}

@media (max-width: 426px) {
    .userDashboard .list-dashboard{
        .date{
            font-size: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: end;
        }
    }
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    .userDashboard .bgImage{height: 380px;}
}

@media (max-width: 394px) {
    .userDashboard .list-dashboard{
        .date{
            font-size: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: start;
        }
    }
}