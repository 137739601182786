.dropdown{

    &.ant-select-dropdown .ant-select-item-option-grouped{
        padding-inline-start: 0 !important;
    }

    &.ant-select-dropdown .ant-select-item{
        padding: 0 !important;
        border-radius: 0 !important;
    }
}

.numberCount{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: white;
    width: 10px;
    height: 10px;
    font-size: 10px;
    background: #208AC6;
    border-radius: 24px;
}

.icon{
    &:hover{
      color: #0E0E2C;
    }
    &:focus{
      color: #0E0E2C;
    }
  }