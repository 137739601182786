@use "src/variable" as base;


.dropdown {


  .ant-select-item-group {
    min-height: auto;
  }

  .ant-divider{
    margin: 5px 0;
  }
}

.workOffers {
  padding: 0 50px;

  .inputField{
    border: 1px solid rgba(14, 14, 44, 0.3);
    border-radius: 3px;
    height: 48px;
    width: 172px;
  }

  .margin-right-32 {
    margin-right: 32px;
  }

  .margin-right-22 {
    margin-right: 22px;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }

  .side-margin-8 {
    margin: 0 8px;
  }

  .white-text {
    color: #FFFFFF;
  }

  .ant-select-selection-item{
    font-weight: 700;
    font-size: 14px;
  }

}