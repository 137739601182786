@use "src/variable" as base;

.company-circle{
  background-color: rgba(base.$orange-100, 0.1);
  width: 100px;
  height: 100px;
  font-weight: 700;
  border-radius: 50%;
  line-height: 100px;
}

.isEmployeeActive{
  background: #A6DC61;
  }

  .isEmployeeNotActive{
    background-color: rgba(14, 14, 44, 0.1);
    padding: 5px;
  }

.ant-table-wrapper .ant-table-container table>thead>tr:first-child th:first-child {
  border-radius: 0px;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child th:last-child {
  border-radius: 0px;
}

.ant-table-wrapper .ant-table-tbody >tr:last-child>td{
  border-bottom: 0px;
}

.icon{
  &:hover{
    color: #0E0E2C;
  }
  &:focus{
    color: #0E0E2C;
  }
}