@use "src/variable" as base;

.modalCandidateAnswers{
  .numberDot{
    height: 24px;
    width: 24px;
    background-color: rgba(32, 138, 198, 0.2);
    border-radius: 24px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    margin-right: 10px;
    @extend .flex-center
  }

  .ant-modal-content{
    padding: 40px 40px
  }
}