@use 'src/variable.scss' as base;

//---------------------------------
// Content

.landing-container {
  max-width: 1200px;
  align-self: flex-start;
  padding: 0 50px;
  flex: 1;

  .landing-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    //---------------------------------
    // Card user e company
    .card-content {
      max-height: 600px;
      flex: 1;
      border: none;
      box-shadow: inset 0 0 0 1px rgba($color: base.$dark-100, $alpha: 0.1);
      padding: 20px 20px;

      .ant-card-body {
        display: flex;
        flex: 1;
      }

      .content-image {
        @extend .half-image;
      }

      .image {
        width: 50%;

        .content-image {
          flex: 1;
        }
      }

      .cardText {
        flex: 1;
        width: 50%;
        flex-direction: column;
      }

      // Titolo
      .header-text {
        @extend .merriWeather;
        @extend .weight-700;
        @extend .center;
        color: base.$dark-100;
        font-size: 32px;
        margin-bottom: -10px;
        white-space: nowrap;
      }

      .color-company {
        color: base.$corp-100
      }

      .color-user {
        color: base.$corp2-100
      }

      // Sottotitolo
      .sub-text {
        font-style: normal;
        @extend .weight-400;
        @extend .center;
        color: base.$dark-100;
        font-size: 16px;
        max-width: 240px;
        padding: 20px 0;
      }


      // Bottone user e company
      .button {
        display: flex;
        justify-content: center;
      }

      .candidate {
        min-width: 100px;
        height: 40px;
      }
    }

    .landingOffer {
      .offerCard {
        .card {
          display: flex;
          align-items: center;
        }

        .offerDescription {
          display: flex;
          row-gap: 8px;
        }
      }
    }

  }
}

//------------------------
// Responsive

@media (max-width: 992px) {
  .landing-container {
    .card-content {
      .header-text {
        margin-top: 10px;
      }

      .image {
        display: none;
      }

      .button {
        width: 100%;

        .button-user, .button-company {
          flex: 1;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-container {
    padding: 0 16px;

    .landing-content {
      .cards {
        flex-direction: column;
      }

      .offers {
        .offerCard {
          flex-direction: column;
          padding: 16px 0 12px 0 !important;

          .offerDescription {
            display: flex;
            flex-direction: column;

            &.dividerDot > ::before {
              content: "" !important;
            }
          }

          .card {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
          }

          .offerDescription {
            flex-direction: column;
          }

          .candidate {
            flex: 1;
            margin-top: 24px;
          }
        }
      }
    }
  }
}