@use 'src/variable.scss' as base;


.alertModal {

  .contentPadding{
    padding: 28px 32px;
  }

  .padding-left-8 {
    padding-left: 8px;
  }

  .padding-bottom-16{
    padding-bottom: 16px;
  }


  .warning{
    background-color: base.$yellow-100;
  }

  .error{
    background-color: base.$corp2-100;
  }


  .headerBar{
    height: 8px;
    width: 100%;
  }

  .ant-modal-content{
    border-radius: 0;
    padding: 0;
  }

  .ant-btn{
    min-width: 100px;
    min-height: 42px;
    width: unset !important;
  }

  .ant-btn-text:not(:disabled):hover{
    color: base.$corp-100;

  }

  .footerPadding{
    padding: 0 32px 32px 32px;
  }

}