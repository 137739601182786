@use "src/variable" as base;


.myCompany {
  padding: 0 50px;

  .margin-right-22 {
    margin-right: 22px;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }

  .colon::after {
    content: ": ";
  }

  .green {
    color: base.$green2-100;
  }

  .orange {
    color: base.$orange-100;
  }

  .grey {
    color: base.$dark-light-90;
  }


  .ant-card-body {
    padding: 32px;
  }

  .profile-square {
    background: rgba(14, 14, 44, 0.1);
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-weight: 700;
  }

  .image-cover {
    width: 50%;
    height: 25%;
    background-repeat: no-repeat;
    background-size: contain;
    @extend .flex-justify-center;
    @extend .margin-top-10;
  }

  .isEmployeeActive{
    width: 25px;
    height: 25px;
    @extend .flex-justify-center;
    line-height: 25px;
    background: #A6DC61;
  }

  .isEmployeeNotActive{
    width: 25px;
    height: 25px;
    @extend .flex-justify-center;
    line-height: 25px;
    background-color: rgba(14, 14, 44, 0.1);
  }

  .icon{
    &:hover{
      color: #0E0E2C;
    }
    &:focus{
      color: #0E0E2C;
    }
  }
}

.margin-12 {
  margin-left: 12px;
  margin-right: 12px;
}
