@use "src/variable" as base;


//---------------------------------
//Content
.containerUserRecoveryEmail{max-width: 1200px; margin: auto;
  .contentUserRecoveryEmail{display: contents;
  // Immagine
    .image{@extend .flex-justify-center; padding: 0 0px 0px 30px;
      .side-image{width: 100%;}
    }
  //---------------------------------
  // Card login user
    .contentRecoveryEmailUser{color: base.$dark-100; padding-top:35px; padding-left: 70px; padding-right: 70px;

      // Header
      header{
        // Indietro
        .header_mobile{h1{font-weight: bolder; font-size: 28px; margin-bottom: 8px;}
        .p_back{@extend .weight-600; @extend .size-17;}
      }
      }
      .header_recovery{width: 100%; margin-top: 2px; margin-bottom: 22px;}

      // Main
      main{
          // Paragrafo
          p{font-size: 14px; font-weight: bold; padding-top: 10px; margin-bottom: 8px; margin-top: 0px;}
      }
      // Footer
      footer{margin-top: 25px;}
    }
  }
}

//------------------------
//Responsive

@media (max-width: 768px) {
  .containerUserRecoveryEmail{/*margin-top: 10px;*/ padding: 0 50px; width: 100%;
    .contentUserRecoveryEmail{width: auto;
      .image{display: flex; justify-content: flex-end !important; width: 100%; height: 175px; position: absolute;
        .side-image{height: 100%; width: auto;}
      }
      .contentRecoveryEmailUser{width: 100%; margin: 0; padding: 0;}
    }
  }
}

@media (max-width: 700px) {
  .containerUserRecoveryEmail{
    .contentUserRecoveryEmail{
      .side-image{display: none;}
      }
    }
}

@media (max-width: 426px) {
  .containerUserRecoveryEmail{margin: 0 !important; padding: 0px !important;
    .contentUserRecoveryEmail{display: flex; justify-content: flex-start !important; flex-direction: column !important;
      .image{width: 0 !important;
        .side-image{display: none;}
      }
      .contentRecoveryEmailUser{
        // Header
        header{width: 100%;
          .header_mobile{width: 100%; background: #FFFFFF; box-shadow: 0px 1px 3px rgba(14, 14, 44, 0.1); font-weight: 700; font-size: 16px; display: flex; justify-content: center;
            // Indietro
            .back{position: absolute; left: 25px; top: 23px;}
            .p_back{display: none;}
          }
        }
        .header_recovery{
          p{padding: 10px 20px;}
        }
        main{padding: 0 20px;
          .ant-form-item{margin: 5px 0;}
        }
        footer{padding: 0 20px;}
      }
    }
  }
}

@media (max-width: 375px) {
  .containerUserRecoveryEmail .contentUserRecoveryEmail .contentRecoveryEmailUser header .header_mobile h1{
    font-size: 18px;
  }
  .containerUserRecoveryEmail .contentUserRecoveryEmail .contentRecoveryEmailUser header .header_mobile .back{
    top: 13px;
  }
  .back{top: 15px !important;}
}

@media (max-width: 320px) {
  .containerUserRecoveryEmail .contentUserRecoveryEmail .contentRecoveryEmailUser header .header_mobile h1{
    font-size: 18px;
  }
  .containerUserRecoveryEmail .contentUserRecoveryEmail .contentRecoveryEmailUser header .header_mobile .back{
    top: 13px;
  }
}
