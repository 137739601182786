@use "src/variable" as base;


.dashboardCard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 0;
  overflow: hidden;
  flex-direction: column;
  height: 136px;
  background-color: #FFFFFF;
  padding: 24px 32px;
  border: 1px solid rgba(base.$dark-100, 0.1);


  .cardIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(base.$orange-100, 0.1);
    margin: 22px;
  }

  .cardSection {
    margin-bottom: 8px;
  }

  .card-title {
    margin-bottom: 10px;
  }

  .data-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .data-container :first-child {
    margin-left: 0;
  }

  .data {
    margin-left: 20px;
  }


  .noWrap{
    white-space: nowrap;
  }

  .underlined {
    text-decoration: underline;
  }

  .blue {
    color: base.$corp-100
  }

  .green {
    color: base.$green2-100;
  }

  .orange {
    color: base.$orange-100;
  }

  .grey {
    color: base.$dark-100;
    opacity: 30%;
  }

  .lilla {
    color: base.$lilla-100;
  }

  .red {
    color: base.$corp2-100;
  }
}