@use "src/variable" as base;


//---------------------------------
//Content
.confirm-container{max-width: 1200px; margin: auto;
  .confirm-content{display: contents; 
  // Immagine
    .image{@extend .flex-justify-center; //padding: 0 0px 0px 30px;
      .side-image{width: 100%;}
    }
  //---------------------------------
  // Card login user
  .cen{@extend .flex-center;
    .textConfirm{width: 100%; padding: 50px 0px;
      h1{
        color: base.$green2-100;
        font-weight: bolder;
        font-size: 28px;
        margin-bottom: 16px;
      }
      h2{
        font-weight: normal;
        font-size: 24px;
        margin-bottom: 24px;
      }
      p{
        font-size: 16px;
      }
    }
      
    }
  }
}

//------------------------
//Responsive

@media (max-width: 768px) {
  .confirm-container{/*margin-top: 10px;*/ padding: 0 50px;// width: 100%;
    .confirm-content{width: auto;
      .image{height: 175px;// display: flex; justify-content: center; align-items: center;// @extend .flex-center;
        .side-image{height: 100%; width: auto;}
      }
      .cen-user{//text-align: center;
      .textConfirm{margin: 0; padding-top: 50px;
        h1{font-size: 18px; padding: 0; margin: 0;}
        h2{font-size: 16px; padding: 0; margin: 5px;}
        p{font-size: 12px; padding: 0; margin: 5px;}
      }}
    }
  }
}

@media (max-width: 426px) {
   .confirm-container{
    .confirm-content{
      .cen{
      .textConfirm{padding: 10px 0;
        h1{font-size: 18px; padding: 0; margin: 0;}
        h2{font-size: 14px; padding: 0; margin: 5px;}
        p{font-size: 10px; padding: 0; margin: 5px;}
      }}
    }
  }
}