.modalNewSite {

  .ant-modal-content {
    padding: 40px;
    border-radius: 0;
  }

  .ant-list-split .ant-list-item:first-child {
    border-block-start: 1px solid rgba(5, 5, 5, 0.06);
  }

  .ant-list-item {
    padding: 0;
  }

  .ant-list-item .flex-column {
    width: 100%;
  }

  .ant-select-selector .ant-select-selection-search-input{
    height: 100% !important;
  }

  .locationTag {
    background-color: #E9F3F9;
    border: none;
    border-radius: 12px;
    padding-inline: 12px;
  }

  .employeeList {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 40px;
  }

  .newLocationList{
    padding: 16px 0;
  }

  .modalFooter{
    display: flex;
    justify-content: flex-end;
  }

  .isEmployeeActive{
    background: #A6DC61;
    }
  
    .isEmployeeNotActive{
      background-color: rgba(14, 14, 44, 0.1);
      padding: 5px;
    }
}
